// JobListingsPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './JobListingsPage.css';

function JobList() {
  const [jobs, setJobs] = useState([
    {
      id: 1,
      title: 'Janitorial Staff',
      description: 'Responsible for cleaning and maintenance of facilities.',
      location: 'London, ON',
    },
    {
      id: 2,
      title: 'Janitorial Staff',
      description: 'Responsible for cleaning and maintenance of facilities.',
      location: 'St Thomas, ON',
    },
    // {
    //   id: 3,
    //   title: 'Window Cleaner',
    //   description: 'Specialized in cleaning windows of high-rise buildings.',
    //   location: 'London, ON',
    // },

    // Add more jobs as needed
  ]);

  return (
    <div className="job-listings-page">
      <h1>Available Positions</h1>
      <div className="jobs-container">
        {jobs.map((job) => (
          <div key={job.id} className="job-card">
            <h2>{job.title}</h2>
            <p>{job.description}</p>
            <p>
              <strong>Location:</strong> {job.location}
            </p>
            <Link to={`/careers`} className="apply-button">
              Apply Now
            </Link>
          </div>
        ))}
      </div>

    </div>
  );
}

export default JobList;

import React, { useState } from "react";
import "./Servicespage1.css";

function ServicesPage() {
  // Initial state to hold the search query and filtered services
  const [searchTerm, setSearchTerm] = useState("");

  // List of services with their titles, descriptions, and image paths
  const services = [
    {
      title: "Strip and Wax Floor Cleaning",
      desc: "A process to restore and protect floors by stripping old wax, cleaning, and applying a fresh coat for shine and durability.",
      image: "./StripandWax.jpg",
    },
    {
      title: "Construction Clean Up",
      desc: "A post-construction service to remove debris, dust, and residue, leaving the space clean and ready for use.",
      image: "./ConstructionCleanUp.jpg",
    },
    
    {
      title: "Commercial Cleaning",
      desc: "Comprehensive cleaning solutions for businesses, ensuring a pristine environment for employees and clients.",
      image: "./CommercialCleaning.jpg",
    },
    {
      title: "Industrial Cleaning",
      desc: "Specialized cleaning services designed for factories and industrial facilities, focusing on safety and compliance.",
      image: "./IndustrialCleaning.jpg",
    },
    {
      title: "Providing Supplies",
      desc: "Delivery of high-quality cleaning supplies and equipment to keep your space spotless and well-maintained.",
      image: "./SuppliesPic.jpg",
    },
    {
      title: "Home and Apartment Cleaning",
      desc: "Thorough cleaning services for residential spaces, tailored to meet the unique needs of each home.",
      image: "./CleaningHomes.jpg",
    },
    {
      title: "Professional Carpet Cleaning",
      desc: "Expert stain removal and deep cleaning for carpets, revitalizing their appearance and extending their lifespan.",
      image: "./CleanCarpets.jpg",
    },
    {
      title: "Car Dealership Cleaning",
      desc: "Specialized cleaning for car dealerships, ensuring vehicles and showrooms are always in top condition.",
      image: "./CleanCarDealerShip.jpg",
    },
    {
      title: "Retail Store Cleaning",
      desc: "Maintaining a clean and inviting atmosphere in retail spaces to enhance customer experience and satisfaction.",
      image: "./retailStore.jpg",
    },
    {
      title: "Professional Office Cleaning",
      desc: "Routine and deep cleaning services for offices, promoting a healthy and productive work environment.",
      image: "./CleanedCorporateOfiice.jpg",
    },
    {
      title: "Residential Cleaning",
      desc: "Personalized cleaning services for homes, ensuring every corner is spotless and comfortable for your family.",
      image: "./personSweeping.jpg",
    },
    {
      title: "Weekly Clean-ups",
      desc: "Regular cleaning services scheduled weekly to maintain cleanliness and order in your space.",
      image: "./manCleaningground.jpg",
    },
    {
      title: "Window Washing",
      desc: "Professional window cleaning services to enhance visibility and brightness in your home or office.",
      image: "./cleaningWindow.jpg",
    },
    {
      title: "Daily Clean Up Cleaning",
      desc: "Daily cleaning services for high-traffic areas, ensuring cleanliness and hygiene at all times.",
      image: "./handDusteruse.jpg",
    },
    {
      title: "Factory Cleaning",
      desc: "Industrial-grade cleaning services for factories, ensuring compliance with safety regulations and cleanliness standards.",
      image: "./ladyCleaningATable.jpg",
    },
  ];

  // Filter services based on the search term in both title and description
  const filteredServices = services.filter(
    (service) =>
      service.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (service.desc &&
        service.desc.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="services-page">
      <strong>
        <h1 className="main-quote">Our Services and Supplies</h1>
      </strong>
      <h1>Cleaning & Janitorial services for the best prices!</h1>

      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search our services!"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      <strong>
        <a href="tel:5192004064">
          <button className="contact-link">📞 Call Now</button>
        </a>
      </strong>

      {/* Services Grid */}
      <style>
        {`

  .grid-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 10px; /* Default padding */
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    text-align: center;
    border: none;
  }

  .gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
  }

  .gallery img {
    width: 100%; /* Adjust as needed for your layout */
    max-width: 800px; /* Limit the image size */
    height: auto;
    margin-bottom: 10px; /* Space between the image and title */
  }

  .gallery h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .gallery h3 {
    font-size: 1rem;
    font-weight: normal;
  }

  /* Media query for phone screens */
  @media (max-width: 768px) {
    .grid-container-wrapper {
      padding: 0; /* Reduce extra padding */
    }

    .grid-container {
      grid-template-columns: 1fr; /* Single column layout */
      gap: 10px; /* Reduce gap between items */
      width: 100%; /* Utilize full width */
    }

    .gallery img {
      max-width: 100%; /* Ensure images scale properly */
      padding: 5px; /* Add minimal padding for images */
    }

    .gallery h2 {
      font-size: 1rem; /* Smaller font size for titles */
    }

    .gallery h3 {
      font-size: 0.875rem; /* Smaller font size for descriptions */
    }
  }


`}
      </style>

      
      <div className="grid-container-wrapper">
      <div className="grid-container">
        {filteredServices.map((service, index) => (
          <div key={index} className="gallery">
            <img src={service.image} alt={service.title} />
            <h2>{service.title}</h2>
            {service.desc && <h3>{service.desc}</h3>}
          </div>
        ))}
      </div>
      </div>
    </div>
  );
}

export default ServicesPage;

// Footer1.js
import React from 'react';
import './CompCSS/footer1.css';
import { Link } from "react-router-dom";

function Footer1() {
  return (
    <footer className="footer">
      <div className="footer-column">
        <strong><h3>Company</h3></strong>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/jobslist">Jobs</a></li>
        </ul>
      </div>

      <div className="footer-column">
        <strong><h3>Business Inquiries</h3></strong>
        
        <ul>
        <li><a href="/contact">Contact Us</a></li>
        <li><a href="/about">About Us</a></li>
        </ul>
      </div>

      <div className="footer-column">
        <strong><h3>Follow Us</h3></strong>
        <ul>
          <li>
            <a href="https://www.facebook.com/echo.cleaning.7" target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/echo-janitorial-services/" target="_blank" rel="noopener noreferrer">
              LinkedIn
            </a>
          </li>

        </ul>
        <div className="employee-signin-container">
        <Link to="/employee-sign-in" className="employee-signin-button">
          Employee Sign-In
        </Link>
      </div>
      </div>
    </footer>
  );
}

export default Footer1;

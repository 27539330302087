import React, { useState } from 'react';
import './contactus.css';
import '../components/CompCSS/navbar1.css';
// eslint-disable-next-line
import { profanity, CensorType } from "@2toad/profanity";

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    province: '',
    city: ''
  });

  const [errors, setErrors] = useState({});

  const validateForm = async () => {
    const newErrors = {};
    const { name, email, phone, message, province, city } = formData;

    if (!/^[A-Za-z\s]+$/.test(name) || name.length < 3) {
      newErrors.name = 'Name must be at least 3 characters and contain only letters.';
    }
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      newErrors.email = 'Email is invalid.';
    }
    if (!/^\d{10}$/.test(phone)) {
      newErrors.phone = 'Phone number must be exactly 10 digits.';
    }
    if (message.length < 10) {
      newErrors.message = 'Message must be at least 10 characters.';
    }
    if (!province) {
      newErrors.province = 'Province is required.';
    }
    if (!city) {
      newErrors.city = 'City is required.';
    }

    // Check for profanity in the message
    if (profanity.exists(message)) {
      newErrors.message = 'Your message contains inappropriate language.';
    }
    if (profanity.exists(name)) {
      newErrors.name = 'Your name field contains inappropriate language.';
    }
    if (profanity.exists(province)) {
      newErrors.province = 'Your province field contains inappropriate language.';
    }
    if (profanity.exists(city)) {
      newErrors.city = 'Your city field contains inappropriate language.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (await validateForm()) {
      const censoredMessage = profanity.censor(formData.message, CensorType.Replace);
  
      const updatedFormData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: censoredMessage,
        province: formData.province,
        city: formData.city,
        subject: "To Mike at Echo Janitorial Services",
        access_key: process.env.REACT_APP_ACCESS_KEY, // Use environment variable
      };
  
      // Debug log to verify payload
      console.log("Payload:", JSON.stringify(updatedFormData, null, 2));
  
      try {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedFormData),
        });
  
        if (response.ok) {
          console.log("Email sent successfully");
  
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
            province: "",
            city: "",
          });
          setErrors({});
        } else {
          const errorData = await response.json();
          console.error("Failed to send email:", errorData);
        }
      } catch (error) {
        console.error("Error sending email:", error);
      }
    } else {
      console.log("Failed Submission");
    }
  };
  

  return (
    <div className="contact-page">
      <title>Contact Us</title >
      <strong>
        <h1>Call Us for Business:</h1>
        <a href="tel:5192004064">
          <button className="contact-link">📞 Call Now</button>
        </a>
      </strong>

      <br /><br /><br />

      <strong><h1>Email us for Services or Business Supplies:</h1></strong>
      <form className="careers-form" onSubmit={handleSubmit}>
        <input type="hidden" name="subject" value="To Mike at Echo Janitorial Services" />

        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className={errors.name ? 'error' : ''}
        />
        {errors.name && <p className="error-message">{errors.name}</p>}

        <label>Business / Corporate Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className={errors.email ? 'error' : ''}
        />
        {errors.email && <p className="error-message">{errors.email}</p>}

        <label>Province:</label>
        <input
          type="text"
          name="province"
          value={formData.province}
          onChange={handleChange}
          className={errors.province ? 'error' : ''}
        />
        {errors.province && <p className="error-message">{errors.province}</p>}

        <label>City:</label>
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
          className={errors.city ? 'error' : ''}
        />
        {errors.city && <p className="error-message">{errors.city}</p>}

        <label>Phone Number:</label>
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          className={errors.phone ? 'error' : ''}
        />
        {errors.phone && <p className="error-message">{errors.phone}</p>}

        <label>Message:</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          className={errors.message ? 'error' : ''}
        />
        {errors.message && <p className="error-message">{errors.message}</p>}

        <button type="submit" className="contact-link">✉️ Send Email</button>
      </form>
    </div>
  );
};

export default ContactUsPage;

import React from 'react';
import './Homepage1.css';

function HomePage() {
  return (
    <div className="homepage">
      <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet"/>
      <header className="hero-section">
        <div className="hero-text">
          <t1 className="hero-title">Echo Janitorial Services</t1>
          <p className="hero-sub-title">JANITORIAL, CUSTODIAL, AND CLEANING SERVICES</p>
          <a href="tel:5192004064" className="call-button">Call Us Now</a>
        </div>
        <div className="hero-image">
          <img src="./handDusteruse.jpg" alt="Person Cleaning" />
        </div>
      </header>
       <strong> <h1 className='main-quote'>WE Love The Jobs You Hate.</h1></strong>
        <h2> All Services At Very Affordable Rates.</h2>

      <p>
      <strong>We are based in London, Ontario, Canada; and do cleaning services
      for businesses or individuals in London and surrounding areas.</strong>
    </p>

      <div className="images-container-big">
        <img className="images-container-big" src="./BusinessCardforEchoJan.png" alt="Business Card" />
      </div>

      <div className="call-button">
        <a href="tel:5192004064" className="call-button">Call Us Now</a>
      </div>
      <br />

      <main>
        <br/>
        <br/>


        <div className="side-by-side-container">
  <div className="quote-container-side-by-side">
    <h1>Echo Janitorial Quote</h1>
    <p>
      We are a fully-bonded-and-insured commercial and residential 
      cleaning company, offering efficient and personal service to London and surrounding areas.
    </p>
  </div>

  <div className="images-container-side-by-side">
    <img src="./personWithMop.jpg" alt="Person With Mop" />
  </div>
</div>

<br/>

        <h1>At Echo Cleaning Services, our commitment goes beyond cleanliness since 1994.</h1>
        <div className='results-button'>
        <a href="/services">Some Results:</a>
        </div>

        <div className="images-container">
          <img src="./BeforeNAfterPic2.jpg" alt="Before and After" />
          <img src="./BeforeNAfterPic1.png" alt="Before and After" />
          <img src="./SteamingStariCase.jpg" alt="Steaming Staircase" />
        </div>
      </main>
    </div>
  );
}

export default HomePage;

import React, { useEffect } from "react"; 
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";



// Import from components folder
import Navbar from "./components/navbar1.jsx"; 
//import ContactButton from "./components/ContactButton.jsx";
import Footer from "./components/Footer1.jsx"; 
import { Helmet } from "react-helmet";


// Import from pages folder
import HomePage from "./Pages/HomePage"; 
import ServicesPage from "./Pages/ServicesPage"; 
import ContactUsPage from "./Pages/ContactUsPage"; 
import AboutUsPage from "./Pages/AboutUsPage"; 
import CareersPage from "./Pages/CareersPage"; 
import JobList from './Pages/JobsList'; // Import your JobList component
// import ManageJobs from "./Pages/ManageJobs"
// import EmployeeSignIn from "./Pages/EmployeeSignIn"


import './App.css';


// ... rest of your App component
function App() { 

  /*useEffect(() => {
    fetch("/api")
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(data => console.log(data))
      .catch(error => console.error('There was a problem with the fetch operation:', error));
  }, []);*/


    useEffect(() => {
      document.title = "Echo Janitorial Services"; // Set a default title
    }, []);

  return ( <Router> 
    <div className="App">

    <title>Echo Janitorial London Ontario</title>

        {/* Helmet for favicon and meta tags */}
        <Helmet>
          <link rel="icon" href="EchoLogo.png" sizes="16x16 32x32 64x64" type="image/png" />
          <meta property="og:title" content="Echo Janitorial London Ontario" />
<meta property="og:description" content="We provide professional cleaning services for homes and businesses in London and surrounding areas." />
<meta property="og:url" content="https://www.echojanitorialservice.com" />
<meta property="og:image" content="EchoLogo.png" />

          <meta name="description" content="Echo Janitorial London Ontario provides professional commercial and residential cleaning services. Fully bonded and insured for efficient and reliable service." />
          <meta name="keywords" content="Janitorial Services, Cleaning Services, London Ontario, St Thomas Ontario, Custodial Services, Echo Janitorial" />
          <meta property="og:title" content="Echo Janitorial Services" />
          <meta property="og:description" content="We provide janitorial, custodial, and cleaning services with a focus on professionalism and customer satisfaction." />
          <meta property="og:image" content="public/EchoLogo.jpg" />
          <meta property="og:image:alt" content="Echo Janitorial Services London logo and tagline" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />

        </Helmet>



   <Navbar /> 
   <Routes> 



     {/*focus on home page for now */}



        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/start" element={<startWorker />} />
        <Route path="/JobsList" element={<JobList />} />
        <Route path="/careers" element={<CareersPage/>} />
        {/* <Route path="/employee-sign-in" element={<EmployeeSignIn/>} />
        <Route path="/manage-jobs" element={<ManageJobs/>} /> */}

      </Routes>
      <Footer />
    </div>
  </Router>
  )}

  export default App;
